var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bg-white mt-4",staticStyle:{"max-width":"95%","margin":"auto","padding":"0.75rem 1rem","border-radius":"0.5rem"},attrs:{"fluid":""}},[_c('div',{staticClass:"bg-main"}),_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":"Containers","active":""},on:{"click":function($event){return _vm.switchTab(_vm.sim=false)}}}),_c('b-tab',{attrs:{"title":"Simtainers"},on:{"click":function($event){return _vm.switchTab(_vm.sim=true)}}})],1),(!this.is_loaded)?_c('div',{staticClass:"loader"},[_c('b-spinner',{staticStyle:{"width":"5rem","height":"5rem"},attrs:{"type":"grow","variant":"info"}})],1):_c('b-table',{attrs:{"striped":"","items":_vm.containerStatus,"fields":_vm.filteredFields},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var ref_item = ref.item;
var running = ref_item.running;
var disconnected = ref_item.disconnected;
return [_c('CircleFill',{attrs:{"variant":running ? 'success' : disconnected ? 'danger' : 'warning',"font-scale":"1.5"}})]}},{key:"cell(robotStatus)",fn:function(ref){
var robot_status = ref.item.robot_status;
return [(true)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('Broadcast',{attrs:{"font-scale":"2","variant":robot_status ? 'success' : 'danger'}})],1):_vm._e()]}},{key:"cell(user)",fn:function(ref){
var ref_item = ref.item;
var user = ref_item.user;
var user_time = ref_item.user_time;
return [(user_time.isActive)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"mr-2"},[_vm._v(_vm._s(user_time.displayTime))]),_c('PersonFill',{attrs:{"font-scale":"3"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(user))])],1):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
var ref_item = ref.item;
var running = ref_item.running;
var inactive = ref_item.inactive;
var disconnected = ref_item.disconnected;
var id = ref_item.id;
return [_c('b-button',{staticClass:"mr-2",attrs:{"variant":"success","disabled":running},on:{"click":function($event){return _vm.startContainer(id)}}},[_vm._v("Start")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"warning","disabled":inactive},on:{"click":function($event){return _vm.stopContainer(id)}}},[_vm._v("Stop")]),_c('b-button',{staticClass:"mr-2",attrs:{"variant":"danger","disabled":disconnected || running},on:{"click":function($event){return _vm.removeContainer(id)}}},[_vm._v("Remove")])]}},{key:"cell(connection)",fn:function(ref){
var ref_item = ref.item;
var inactive = ref_item.inactive;
var vnc_uri = ref_item.vnc_uri;
return [_c('b-button',{attrs:{"variant":"primary","disabled":inactive,"target":"_blank","href":vnc_uri}},[_vm._v("Connect")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }