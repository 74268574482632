var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"panel",attrs:{"fluid":""}},[_c('div',{staticClass:"text-center panel-title"},[_vm._v("My sessions")]),(!this.is_loaded)?_c('div',{staticClass:"loader"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"type":"grow","variant":"info"}})],1):_c('b-row',[_c('b-col',[_c('b-table',{staticClass:"session-table",attrs:{"items":_vm.bookings,"fields":_vm.fields,"striped":"","outlined":"","head-variant":"dark","tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(join)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{staticStyle:{"font-size":"1.3rem","padding":"0.5rem 1.5rem"},attrs:{"disabled":!item.isActive},on:{"click":function($event){return _vm.$router.push({ name: 'Session', params: {session: item.id} })}}},[_vm._v(" Session dashboard ")])]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex mr-4 align-items-center ml-4"},[(item.is_simulation)?_c('b-img',{staticClass:"slot-icon",attrs:{"src":require('../assets/robotont-sim.png')}}):_c('b-img',{staticClass:"slot-icon",attrs:{"src":require('../assets/robotont-right.png')}}),_c('span',{staticClass:"booking-title ml-5",staticStyle:{"font-size":"1.5rem"}},[_vm._v(_vm._s(item.title))])],1)]}},{key:"cell(start)",fn:function(ref){
var item = ref.item;
return [_c('Clock',{attrs:{"variant":"primary"}}),_vm._v(" "+_vm._s(_vm.parseDate(item.start))+" ")]}},{key:"cell(end)",fn:function(ref){
var item = ref.item;
return [_c('Clock',{attrs:{"variant":"primary"}}),_vm._v(" "+_vm._s(_vm.parseDate(item.end))+" ")]}},{key:"cell(countdown)",fn:function(ref){
var item = ref.item;
return [_c('div',{key:_vm.timerKey,staticClass:"timer"},[(!item.isActive && !item.isExpired)?_c('span',[_vm._v("Starts in: "+_vm._s(item.displayTime))]):(!item.isExpired)?_c('span',[_vm._v("Time left: "+_vm._s(item.displayTime))]):_c('span',[_vm._v("Finished")])])]}}])})],1)],1),(this.is_loaded && this.bookings.length === 0)?_c('b-row',{staticClass:"no-session-message"},[_vm._v(" You haven't reserved any time slots yet! ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }